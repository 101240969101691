import React from "react";
import Images from "../../../assets/0-exporter";
import { resetPassword } from "../../../services/postAPIs";

export default function RSThree({ setLoading, details, setStep, setDetails }) {
  const [display, setDisplay] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  const handleSubmit = async () => {
    setLoading(true);
    let res = await resetPassword(details);
    if (res.data.status) {
      setStep(3);
      setLoading(false);
    } else {
      setStep(0);
      alert(res.data.message);
    }
  };
  return (
    <div className="reset-password-three">
      <img src={Images.accountsLogo} />
      <div className="r-s-two-enter-password">
        <p>New Password</p>
        <input
          autoComplete="new-password"
          value={details.newPassword}
          onChange={(e) =>
            setDetails({ ...details, newPassword: e.target.value })
          }
          type="password"
          //   placeholder="New Password"
        />
        <span
          className={
            passwordRegex.test(details.newPassword) ? "valid" : "invalid"
          }
        ></span>
      </div>
      <div className="r-s-two-enter-password">
        <p>Confirm Password</p>
        <input
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          //   placeholder="Confirm Password"
        />
        <span
          className={
            password && password === details.newPassword ? "valid" : "invalid"
          }
        ></span>
      </div>
      <button
        onClick={() => handleSubmit()}
        disabled={!passwordRegex.test(details.newPassword)}
      >
        Next
      </button>
      <div
        className={ `password-requirement ${display ? "full-width" : ""}`
        }
      >
        <div onClick={() => setDisplay(!display)} className="p-r-header">
          {!display ? "Password Requirements" : "Go Back"}
        </div>
        <div className="p-r-body">
          <ul>
            <li>Must Contain At Least One Capital Letter</li>
            <li>Must Contain At Least One Number</li>
            <li>Must Contain At Least One Special Character</li>
            <li>Length Of The Password Should Be Of Minimum 8</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
