import React from "react";
import LoadingAnimation from "../../../lotties/LoadingAnimation";

export default function DownloadLoading() {
  return (
    <div className="download-loading">
      <LoadingAnimation />
    </div>
  );
}
