import React from "react";
import { useHistory } from "react-router";
import LoadingAnimation from "../../../lotties/LoadingAnimation";
import { login } from "../../../services/postAPIs";
import "./reset-password.style.scss";
import RSFour from "./RSFour";
import RSOne from "./RSOne";
import RSThree from "./RSThree";
import RSTwo from "./RSTwo";
export default function ResetPassword({ step,setType,setCredential, setStep }) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [details, setDetails] = React.useState({
    email: "",
    password: "",
    code: "",
    newPassword: "",
  });
  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <RSOne
            setLoading={setLoading}
            details={details}
            setStep={setStep}
            setDetails={setDetails}
          />
        );
      case 1:
        return (
          <RSTwo
            setLoading={setLoading}
            details={details}
            setStep={setStep}
            setDetails={setDetails}
          />
        );
      case 2:
        return (
          <RSThree
            setLoading={setLoading}
            details={details}
            setStep={setStep}
            setDetails={setDetails}
          />
        );
      case 3:
        return (
          <RSFour
          setType={setType}
            setLoading={setLoading}
            details={details}
            setStep={setStep}
            setDetails={setDetails}
            setCredential={setCredential}
          />
        );

      default:
        break;
    }
  };
  return (
    <div className="reset-password-main">
      {loading ? <LoadingAnimation /> : renderStep()}
    </div>
  );
}
