import React from 'react'
import Images from '../assets/0-exporter'
import './manual-loading.style.scss'
export default function ManualLoading() {
    return (
        <div className="manual-loading">
            <img src={Images.mainLogo} />
            
        </div>
    )
}
