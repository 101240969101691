import React from "react";
import reactDom from "react-dom";
import { useHistory, useParams } from "react-router";
import Images from "../../../assets/0-exporter";
import { Context } from "../../Context/Context";
import DownloadPortal from "../../Portals/DownloadPortal/DownloadPortal";
import MainMenu from "../../Portals/MainMenu/MainMenu";
import AccountSelection from "../AccountSelection/AccountSelection";
import BondEarningsTable from "./BondEarnings/BondEarningsTable";
import "./dashboard.style.scss";
import LiquidAsset from "./LiquidAsset/LiquidAsset";
import MoneyMarketTable from "./MoneyMarket/MoneyMarketTable";
import SplitTable from "./SplitTable/SplitTable";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
export default function Dashboard() {
  const downloadRef = React.useRef();
  const history = useHistory();
  const { email, app, type, subType } = useParams();
  const context = React.useContext(Context);
  const {
    tabsConfig,
    selectedTab,
    refreshTable,
    openDownloadTab,
    userDetails,
    updateState,
    currencyImageList,
    valueFormatter,
    registeredApps,
    nameImageList,
    miniTabSelected,
    refresh,
  } = context;
  const [addType, setAddType] = React.useState("");
  const [openMenu, setOpenMenu] = React.useState(false);
  const [splitNum, setSplitNum] = React.useState(0);

  const handlePDFDownload = () => {
    savePDF(downloadRef.current, {
      paperSize: "A1",
      fileName: `${selectedTab.app.app_name}-${type}-${selectedTab.subType} Ledger.pdf`,
    });
  };

  const handleRemoveApp = (obj) => {
    if (tabsConfig.length === 1) {
      localStorage.removeItem("tabsConfig");
      localStorage.removeItem("selectedTab");
      localStorage.removeItem("miniTabSelected");
      localStorage.removeItem("tabApps");
      let tempSub = tabsConfig[0].subTabs;
      tempSub.map((x) => {
        localStorage.removeItem(x?.keyId);
      });
      refresh();
      history.push("/select");
      return;
    } else {
      let index = 0;
      let temp = tabsConfig.filter((x, num) => {
        if (x.id === obj.id) {
          index = num;
        }
        return x.id !== obj.id;
      });
      let nextApp;
      if (index === 0) {
        nextApp = tabsConfig[index + 1];
      } else {
        nextApp = tabsConfig[index - 1];
      }

      updateState("selectedTab", nextApp);
      history.push(`/${email}/${nextApp.id}/${nextApp.subTabs[0].id}`);
      updateState("tabsConfig", [...temp]);
    }
  };
  const handleRemoveType = (obj) => {
    if (selectedTab.subTabs.length === 1) {
      return;
    } else {
      let indexTwo = 0;
      let coin = "";
      let index = tabsConfig.findIndex((x) => {
        return x.id === selectedTab.id;
      });
      let newTab = null;
      let subTabTemp = selectedTab.subTabs.filter((x, num) => {
        if (x.id === obj.id && x.type === obj.type) {
          indexTwo = num;
          newTab = selectedTab.subTabs[num - 1];
        }
        return x.id !== obj.id || x.type !== obj.type;
      });
      let keyIdTemp = selectedTab.keyId;
      let temporary = [];
      Object.keys(keyIdTemp).map((x) => {
        if (obj.id === x) {
          let arr = keyIdTemp[x];
          if (arr.length === 1) {
            temporary = [];
          } else {
            temporary = arr.filter((y) => {
              return y !== obj.type;
            });
          }
        }
      });
      keyIdTemp[obj.id] = temporary;

      tabsConfig[index] = {
        ...selectedTab,
        subTabs: [...subTabTemp],
        keyId: { ...keyIdTemp },
      };
      updateState("tabsConfig", [...tabsConfig]);
      updateState("selectedTab", {
        ...selectedTab,
        subTabs: [...subTabTemp],
        keyId: { ...keyIdTemp },
      });
      localStorage.removeItem(miniTabSelected?.keyId);
      updateState("miniTabSelected", { ...newTab });
      console.log("remain", newTab);
      // if (coin !== type) return;
      history.push(`/${email}/${app}/${newTab.id}/${newTab.type}`);
    }
  };
  const removeSubType = (num) => {
    let index = tabsConfig.findIndex((x) => {
      return x.id === selectedTab.id;
    });

    let tempObj = { ...selectedTab };
    tempObj.subTabs[num] = { ...tempObj.subTabs[num], subType: false };
    updateState("selectedTab", { ...tempObj });
    updateState("miniTabSelected", { ...tempObj });
    tabsConfig[index] = { ...tempObj };
    updateState("tabsConfig", [...tabsConfig]);
  };
  React.useEffect(() => {}, [miniTabSelected]);
  const handleMenuClick = (obj) => {
    switch (obj.id) {
      case "store":
        break;
      case "refresh":
        updateState("refreshTable", true);
        let a = setTimeout(() => {
          updateState("refreshTable", null);
          clearTimeout(a);
        }, 400);
        break;
      case "theatre":
        break;
      case "chats":
        break;
      case "accountant":
        break;
      case "logout":
        localStorage.clear();
        history.push("/login");
        break;

      default:
        break;
    }
  };
  const renderAccountSelection = () => {
    switch (addType) {
      case "app":
        return (
          <AccountSelection
            type="app"
            closeIt={() => setAddType("")}
            app={true}
          />
        );

        break;
      case "type":
        return (
          <AccountSelection
            closeIt={() => setAddType("")}
            selectedApp={selectedTab.app}
            type={true}
            keyIds={selectedTab.keyId}
            type="type"
          />
        );

        break;
      case "split":
        return (
          <AccountSelection
            type="split"
            selectedApp={selectedTab.app}
            closeIt={() => setAddType("")}
            splitNum={splitNum}
          />
        );

      default:
        return "";
    }
  };

  const renderMainTable = () => {
    switch (subType) {
      case "L":
        return (
          <LiquidAsset
            downloadRef={downloadRef}
            split={!miniTabSelected?.subType ? false : true}
          />
        );
      case "BE":
        return (
          <BondEarningsTable
            downloadRef={downloadRef}
            split={!miniTabSelected?.subType ? false : true}
            app={app}
            email={email}
            coin={type}
          />
        );
      case "MM":
        return (
          <MoneyMarketTable
            downloadRef={downloadRef}
            split={!miniTabSelected?.subType ? false : true}
            app={app}
            email={email}
            coin={type}
          />
        );

      default:
        break;
    }
  };
  const handleCard = (obj) => {
    if (obj.id === app) return;
    updateState("selectedTab", obj);
    history.push(
      `/${localStorage.getItem("userEmail")}/${obj.id}/${obj.subTabs[0].id}/${
        obj.subTabs[0].type
      }`
    );
    updateState("miniTabSelected", { ...obj.subTabs[0] });
  };

  React.useEffect(() => {
    if (!tabsConfig.length) {
      history.push(`/select`);
    }
  }, []);

  React.useEffect(() => {
    if (!selectedTab) return;
    let tempObj = selectedTab.subTabs.find((x) => {
      return x.id === type && x.type === subType;
    });
    updateState("miniTabSelected", { ...tempObj });
  }, [type]);

  return (
    <div className="dashboard-main">
      {openMenu ? (
        <MainMenu
          handleClick={handleMenuClick}
          closeIt={() => setOpenMenu(false)}
          userDetails={userDetails}
        />
      ) : (
        ""
      )}
      {openDownloadTab ? (
        <DownloadPortal
          subType={subType}
          handlePDFDownload={handlePDFDownload}
          email={email}
          registeredApps={registeredApps}
          nameImageList={nameImageList}
          valueFormatter={valueFormatter}
          type={type}
          closeIt={() => updateState("openDownloadTab", false)}
          selectedTab={selectedTab}
          userDetails={userDetails}
        />
      ) : (
        ""
      )}
      <div className={addType ? "add-app-modal" : "d-none"}>
        {renderAccountSelection()}
      </div>
      <div className="dashboard-header">
        <div onClick={() => setOpenMenu(true)} className="d-h-title">
          <img src={Images.accountsLogo} alt="accounts" />
        </div>
        <div className="d-h-tabs">
          <div className="d-h-tabs-wrapper">
            {tabsConfig.map((obj) => (
              <div
                onClick={() => handleCard(obj)}
                className={obj.id === app ? "selected-tab" : ""}
              >
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveApp(obj);
                  }}
                  className="cancel"
                  src={Images.cancel}
                  alt="cancel"
                />
                <h4>
                  <img className="main-image" src={obj?.app?.app_icon} />{" "}
                  {obj?.app?.app_name}
                </h4>
              </div>
            ))}
          </div>
        </div>
        <div onClick={() => setAddType("app")} className="d-h-add">
          <img src={Images.plus} />
        </div>
      </div>
      <div className="dashboard-sub-header">
        <div className="dashboard-sub-header-wrapper">
          {!selectedTab
            ? ""
            : selectedTab.subTabs.map((obj, num) => (
                <div
                  onClick={() => {
                    console.log("just now", obj);
                    updateState("miniTabSelected", obj);
                    history.push(
                      `/${localStorage.getItem("userEmail")}/${app}/${obj.id}/${
                        obj.type
                      }`
                    );
                  }}
                  className={
                    obj.id === type && obj.type === subType
                      ? "selected-sub-tab"
                      : ""
                  }
                >
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveType(obj);
                    }}
                    className="cancel"
                    src={Images.cancel}
                    alt="cancel"
                  />
                  <img className="main-image" src={obj.icon} />{" "}
                  <h6>
                    {obj.name} {"("}
                    {obj.type}
                    {")"}
                    {obj.subType ? (
                      <p
                        onClick={(e) => {
                          e.stopPropagation();
                          obj.id === type && obj.type === subType
                            ? removeSubType(num)
                            : console.log();
                        }}
                      >
                        <img src={obj.subType.coin.icon} />
                        {obj.subType.coin.name}
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveType(obj);
                          }}
                          className="cancel"
                          src={Images.cancel}
                          alt="cancel"
                        />
                      </p>
                    ) : (
                      <button
                        onClick={() => {
                          setAddType("split");
                          setSplitNum(num);
                        }}
                      >
                        <img src={Images.plus} />
                      </button>
                    )}
                  </h6>
                </div>
              ))}
        </div>
        <div className="dashboard-sub-header-options">
          <button>
            <img src={Images.settings} />
          </button>
          <button onClick={() => setAddType("type")}>
            {" "}
            <img src={Images.plus} />
          </button>
        </div>
      </div>

      <div className="dashboard-sub-body">
        <div
          className={`dashboard-main-table ${
            !miniTabSelected?.subType ? "" : "half-main-table"
          }`}
        >
          {renderMainTable()}
        </div>
        <div
          className={`dashboard-split-table ${
            !miniTabSelected?.subType ? "" : "full-split-table"
          }`}
        >
          {!miniTabSelected?.subType ? (
            ""
          ) : (
            <SplitTable
              currencyImageList={currencyImageList}
              config={miniTabSelected?.subType}
            />
          )}
        </div>
      </div>
    </div>
  );
}
