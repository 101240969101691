import React from "react";
import Images from "../../../assets/0-exporter";
import OtpInput from "react-otp-input";
import "./two-fa.style.scss";
import { _2FALogin } from "../../../services/postAPIs";
import { useHistory } from "react-router";
import LoadingAnimation from "../../../lotties/LoadingAnimation";
export default function TwoFA({ credential }) {
  const history = useHistory();
  const [pin, setPin] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const handleChange = (e) => {
    setError(false);
    setPin(e);
  };
  const [error, setError] = React.useState(false);
  const handleEnter = async (e) => {
    if (e.keyCode === 13) {
      setLoading(true);
      let res = await _2FALogin({ ...credential, totp_code: pin });
      if (res.data.status) {
        localStorage.setItem("userEmail", credential.email);
        localStorage.setItem("deviceKey", res.data.device_key);
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("appCode", "bankerapp");
        localStorage.setItem("idToken", res.data.idToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        history.push("/select");
      } else {
        setError(true);
      }
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (pin.length === 6) {
      document.addEventListener("keydown", handleEnter);
    } else {
      document.removeEventListener("keydown", handleEnter);
    }
    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [pin]);
  return (
    <div className="two-fa-main">
      {loading ? (
        <div className="_2fa-loading">
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <img src={Images.accountsLogo} alt="accounts" />
          <div className={`ot-input-wrapper ${error ? "error-input" : ""}`}>
            <p>Enter The 2FA Code From Google Authenticator</p>
            <OtpInput
              placeholder="######"
              isInputSecure={false}
              isInputNum={true}
              shouldAutoFocus={true}
              numInputs={true}
              style={{ width: 100 }}
              value={pin}
              onChange={handleChange}
              numInputs={6}
            />
          </div>
        </>
      )}
    </div>
  );
}
