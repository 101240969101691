import React from "react";
import { useHistory } from "react-router";
import Images from "../../assets/0-exporter";
import "./landing-page.style.scss";
export default function LandingPage() {
  const history = useHistory();
  return (
    <div className="landing-page-main">
      <div className="landing-page-bg">
        <div />
        <img src={Images.lady} />
      </div>
      <div className="landing-title">
        <img src={Images.accountsLogoWhite} alt="accounts" />
        {/* <p>Simple . Social . Streamlined</p> */}
      </div>
      <div className="landing-buttons">
        <button>Get Started</button>
        <button onClick={()=>history.push("/login")}>Login</button>
      </div>
    </div>
  );
}
