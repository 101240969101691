import React from "react";

export default function DownloadAppList({ setConfig, setWhich,config, registeredApps }) {
  return (
    <div className="download-app-list">
      {registeredApps.map((obj) => (
        <div className="r-app-wrapper">
          <div className="r-app-container">
            <div onClick={() =>{setWhich(""); setConfig({ ...config, app: obj })}}>
              <img src={obj.app_icon} />
            </div>
            <p>{obj.app_name}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
