import React from "react";
import { useHistory } from "react-router";
import Images from "../../../assets/0-exporter";
import { login } from "../../../services/postAPIs";

export default function RSFour({ details, setType, setCredential, setStep }) {
  const history = useHistory();
  const handleLogin = async () => {
    let res = await login(details.email, details.newPassword);
    if (res.data.status) {
      localStorage.setItem("userEmail", details.email);
      localStorage.setItem("deviceKey", res.data.device_key);
      localStorage.setItem("accessToken", res.data.accessToken);
      localStorage.setItem("idToken", res.data.idToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      localStorage.setItem("appCode", "bankerapp");
      history.push("/dashboard");
    } else if (res.data.mfa) {
      setCredential({ email: details.email, password: details.newPassword });
      setType("2fa");
    } else {
      setType("normal");
      setStep(0);
      alert("Something Went Wrong.");
    }
  };
  React.useEffect(() => {
    handleLogin();
  }, []);
  return (
    <div className="reset-password-four">
      <div className="r-p-step-four">
        <img src={Images.accountsLogo} />
        <h5>Your Password Has Been Reset Successfully</h5>
        <p>You Will Be Logged In Automatically </p>
      </div>
    </div>
  );
}
