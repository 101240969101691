import React from "react";
import { useHistory } from "react-router";
import AppAssets from "../../CommonComponents/AppAssets/AppAssets";
import RegisteredApps from "../../CommonComponents/RegisteredApps/RegisteredApps";
import TypeOfAsset from "../../CommonComponents/TypeOfAsset/TypeOfAsset";
import TypeOfStreams from "../../CommonComponents/TypeOfStreams/TypeOfStreams";
import { Context } from "../../Context/Context";
import "./acount-selection.style.scss";
import CardSkeleton from "./CardSkeleton/CardSkeleton";
export default function AccountSelection({
  type,
  keyIds = [],
  selectedApp = null,
  closeIt,
  splitNum,
  app,
}) {
  const history = useHistory();
  const context = React.useContext(Context);

  const {
    registeredApps,
    tabsConfig,
    userDetails,
    selectedTab,
    tabApps,
    updateState,
    currentTheme,
    miniTabSelected,
  } = context;
  const [selectionStep, setSelectionStep] = React.useState(
    type === "type" ? 1 : 0
  );
  const [splitInfo, setSplitInfo] = React.useState({
    app: null,
    coin: null,
    type: null,
  });
  const [details, setDetails] = React.useState({
    app: selectedApp,
    stream: null,
    streamType: null,
  });

  const handleSplit = (obj) => {
    console.log("split", obj, splitInfo);
    let index = tabsConfig.findIndex((x) => {
      return x.id === selectedApp.app_code;
    });
    let temp = tabsConfig.find((x) => {
      return x.id === selectedApp.app_code;
    });
    if (!temp) {
      closeIt();
    }
    let tempOj = {
      ...temp.subTabs[splitNum],
      subType: { ...splitInfo, type: obj.id },
    };
    temp.subTabs[splitNum] = { ...tempOj };

    tabsConfig[index] = { ...temp };
    updateState("miniTabSelected", {
      ...miniTabSelected,
      subType: { ...splitInfo, type: obj.id },
    });
    updateState("selectedTab", { ...temp });
    updateState("tabsConfig", [...tabsConfig]);
    closeIt();
  };

  const handleFinalClickAsset = (obj) => {
    let temp = tabsConfig.findIndex((x) => {
      return x.app.app_code === selectedApp.app_code;
    });
    let tempObj = tabsConfig[temp];
    if (temp < 0) {
      closeIt();
    } else {
      let tempKeyId;
      if (Object.keys(tempObj.keyId).includes(details.streamType.id)) {
        tempKeyId = {
          ...tempObj.keyId,
          [details.streamType.id]: [
            ...tempObj.keyId[details.streamType.id],
            obj.id,
          ],
        };
      }
      //tempObj.id +  details.streamType.keyId + obj.id
      console.log("coming 2", details, tempObj, obj);

      tabsConfig[temp] = {
        ...tempObj,
        subTabs: [
          ...tempObj.subTabs,
          {
            ...details.streamType,
            keyId: tempObj.id + details.streamType.keyId + obj.id,
            subType: false,
            type: obj.id,
          },
        ],
        keyId: { ...tempKeyId },
      };
      updateState("selectedTab", {
        ...tempObj,
        subTabs: [
          ...tempObj.subTabs,
          {
            ...details.streamType,
            keyId: tempObj.id + details.streamType.keyId + obj.id,
            subType: false,
            type: obj.id,
          },
        ],
        keyId: { ...tempKeyId },
      });
      updateState("miniTabSelected", {
        ...details.streamType,
        keyId: tempObj.id + details.streamType.keyId + obj.id,
        subType: false,
        type: obj.id,
      });
      updateState("tabsConfig", [...tabsConfig]);
      history.push(
        `/${userDetails.email}/${details.app.app_code}/${details.streamType.id}/${obj.id}`
      );
      closeIt();
    }
  };
  const handleFinalClick = (obj) => {
    console.log("coming", details, obj);
    let temp = {
      id: details.app.app_code,
      app: details.app,
      subTabs: [
        {
          ...details.streamType,
          keyId: details.app.app_code + details.streamType.keyId + obj.id,
          subType: false,
          type: obj.id,
        },
      ],
      keyId: { [details.streamType.id]: [obj.id] },
    };

    updateState("tabsConfig", [...tabsConfig, { ...temp }]);
    updateState("selectedTab", temp);
    updateState("miniTabSelected", {
      ...details.streamType,
      keyId: details.app.app_code + details.streamType.keyId + obj.id,
      subType: false,
      type: obj.id,
    });
    updateState("tabApps", [...tabApps, details.app.app_code]);
    if (type === "app") {
      closeIt();
    }
    history.push(
      `/${userDetails.email}/${details.app.app_code}/${details.streamType.id}/${obj.id}`
    );
  };
  const renderSteps = () => {
    switch (selectionStep) {
      case 0:
        return (
          <RegisteredApps
            split={type === "split"}
            tabApps={tabApps}
            userDetails={userDetails}
            registeredApps={registeredApps}
            handleClick={(obj) => {
              type === "split"
                ? setSplitInfo({ ...splitInfo, app: obj })
                : setDetails({ ...details, app: obj, stream: obj.id });
              setSelectionStep(2);
            }}
          />
        );
      // case 1:
      //   return (
      //     <TypeOfStreams
      //       userDetails={userDetails}
      //       handleClick={(obj) => {
      //         setDetails({ ...details, stream: obj.id });
      //         setSelectionStep(2);
      //       }}
      //     />
      //   );
      case 2:
        return (
          <AppAssets
            keyIds={keyIds}
            selectedApp={type === "split" ? splitInfo.app : details.app}
            handleClick={(obj) => {
              type === "split"
                ? setSplitInfo({ ...splitInfo, coin: obj })
                : setDetails({ ...details, streamType: obj });
              setSelectionStep(3);

              // type ? handleFinalClickAsset(obj) : handleFinalClick(obj);
            }}
          />
        );
      case 3:
        return (
          <TypeOfAsset
            handleClick={(obj) =>
              type === "type"
                ? handleFinalClickAsset(obj)
                : type === "split"
                ? handleSplit(obj)
                : handleFinalClick(obj)
            }
            details={details}
            coins={!selectedTab ? {} : app ? {} : selectedTab.keyId}
          />
        );

      default:
        break;
    }
  };
  const handleTheme = () => {
    let current = localStorage.getItem("theme");
    if (current === "dark") {
      localStorage.setItem("theme", "light");
      updateState("currentTheme", "light");
      document.documentElement.setAttribute("data-theme", "light");
    } else {
      localStorage.setItem("theme", "dark");
      updateState("currentTheme", "dark");
      document.documentElement.setAttribute("data-theme", "dark");
    }
  };
  return (
    <div
      onClick={() => (type ? closeIt(false) : console.log())}
      style={type ? { backgroundColor: "transparent" } : {}}
      className="account-selection"
    >
      <button
        onClick={() => setSelectionStep(selectionStep - 1)}
        disabled={!selectionStep}
        className={type ? "d-none" : "selection-back"}
      >
        Back
      </button>
      <button
        onClick={() => handleTheme()}
        className={type ? "d-none" : "dual-theme-holder"}
      >
        {currentTheme.toUpperCase()}
      </button>
      <div onClick={(e) => e.stopPropagation()} className="selection-area">
        {!registeredApps || !userDetails ? <CardSkeleton /> : renderSteps()}
      </div>
    </div>
  );
}
