import React from "react";
import Images from "../../../assets/0-exporter";
import { forgetPasswordRequest } from "../../../services/postAPIs";

export default function RSOne({ setLoading, details, setStep, setDetails }) {
  const emailRegex = /([\w\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?/gm;
  const [error, setError] = React.useState(false);
  const handleClick = async () => {
    setLoading(true);
    let res = await forgetPasswordRequest(details.email);
    if (res.data.status) {
      setStep(1);
    } else {
      setError(true);
      setStep(0);
    }
    setStep(1)
    setLoading(false);
  };
  return (
    <div className="reset-password-one">
      <img src={Images.accountsLogo} />
      <div className={error ? "error" : ""}>
        <input
          value={details.email}
          type="email"
          placeholder="Email"
          onKeyPress={(e) =>
            e.which === 13 && emailRegex.test(details.email)
              ? handleClick()
              : console.log()
          }
          onChange={(e) => {
            setDetails({ ...details, email: e.target.value });
            setError(false);
          }}
        />
      </div>
      <button
        disabled={!emailRegex.test(details.email)}
        onClick={() => handleClick()}
      >
        Next
      </button>
    </div>
  );
}
